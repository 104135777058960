import { Pipe, PipeTransform } from '@angular/core';
import { isToday } from 'date-fns';
import { stringOrDateToDate } from '../utilities/date';

@Pipe({
  name: 'isToday',
})
export class IsTodayPipe implements PipeTransform {
  public transform(value: string | Date): boolean {
    return isToday(stringOrDateToDate(value));
  }
}
