import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LohiLoadStatus, NullableXYPoint, XYPoint } from '../../shared/types';
import { RouterFinn } from '../../shared/utilities/routerFinn';
import { RouterStateService } from '../../shared/services/router-state.service';
import { LOAD_ID } from '../../shared/constants';
import { shareReplay } from 'rxjs/operators';
import { BehaviorSubject, catchError, debounceTime, lastValueFrom, Observable, of, Subject, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';

export enum StopType {
  pickup = 'pickup',
  dropoff = 'dropoff',
  emptyAssetPickup = 'empty_asset_pickup',
  emptyAssetDropoff = 'empty_asset_dropoff',
  loadedAssetStorageDropoff = 'loaded_asset_storage_dropoff',
  loadedAssetStoragePickup = 'loaded_asset_storage_pickup',
}

export enum LoadingType {
  live = 'live',
  drop = 'drop',
}

export interface LoadDetails {
  payloadName: string | null;
  carrierSCAC: string;
  subscribed: boolean;
  customerRateCents: number;
  id: string;
  status: LohiLoadStatus;
  createdAt: string;
  updatedAt: string;
  companyId: string;
  companyName: string;
  companyMCNumber: string;
  companyDOTNumber: string;
  driverId: string;
  driverName: string;
  driverPhone: string;
  driverLastWaypointUpdateAt: string;
  driverLastWaypointLngLat: NullableXYPoint;
  shipper: string;
  shipperId: string;
  broker: any;
  rateCents: number;
  rateCentsPerMile: number;
  weightPounds: number;
  tempFahrenheit: any;
  details: string;
  shippingComments: any;
  stops: Stop[];
  files: File[];
  trailerTypes: TrailerType[];
  tripMileage: number;
  deadheadMiles: number;
  durationMinutes: number;
  bolNumber: string | null;
  actualQuantity: number | null;
  completedAt: string;
  adjustedCompletedAt: string;
  referenceNumber: string;
  brokerNotificationMinutes: number;
  trailerDisplayName: string;
  possibleTrailerTypes: TrailerType[];
  acknowledgedAt: string;
  acknowledgedBy: string;
  acknowledgedByName: string;
  creatorCustomerName: any;
  creatorCustomerEmail: any;
  creatorCustomerPhone: any;
  creatorCustomerType: any;
  createdByUserName: string;
  hasCarrierAssignmentRequest: boolean;
  isAwaitingRateConSignature: boolean;
  customerId: string;
  customerPlatformFeeRate: number;
  customerMarginTakeRate: number;
  loadBillingApprovedAt: string;
  isPaid: boolean;
  isCPG: boolean;
  isDrayage: boolean;
  isMatchback: boolean;
  isAutoDispatchable: boolean;
  isAutoDispatchOnly: boolean;
  dispatcherName: string;
  requestRateLoad: boolean;
  customerBrokerSubsidiaryId: string;
  poolId: number;
  vpfAutodispatchLocked: boolean;
  allowVPFAutodispatchLock: boolean;
  lamTrailer?: {
    id: number;
    name: string;
  };
}

export interface Stop {
  id: number;
  loadId: string;
  type: StopType;
  loadingType: LoadingType;
  sequence: number;
  lngLat: XYPoint;
  title: string;
  address: string;
  city: string;
  state: string;
  stateCode: string;
  zipCode: string;
  arrivalWindowStartsAtExtrema: string;
  arrivalWindowStartsAt: string;
  arrivalWindowMinutes: number;
  arrivalWindowEndsAtExtrema: string;
  originalArrivalWindowStartsAt?: string;
  details: string;
  commodity: string;
  quantity: number;
  unit: Unit;
  completedAt: string;
  referenceNumber: string;
  detentionFreeMinutes: number;
  appointmentTrackingNumber: string;
  arrivedAt: string;
  exitedAt?: string;
  eta: string;
  etaUpdatedAt: string;
  facilityId: string;
  facilityName: string;
  facilityFCFS: boolean;
  facilityApptRequired: boolean;
  facilityCustomerReferenceNumber: string;
  facilityOwnerId: string;
  timeZone: TimeZone;
  isFCFS: boolean;
  siteRadiusMiles: number;
  facilityExternalId: string;
  attachEmptyTrailerPickup: boolean;
}

export interface Unit {
  id: number;
  name: string;
  abbreviation: string;
}

export interface TimeZone {
  name: string;
  abbreviation: string;
}

export interface File {
  id: number;
  name: string;
  destination: string;
  url: string;
  category: string;
  uploadedAt: string;
  uploadedBy: string;
  archivedAt: any;
  archivedBy: any;
  passedQa: any;
  qaAt: any;
  qaBy: any;
  qaRejectedReason: any;
  displayName: string;
}

export interface TrailerType {
  id: string;
  name: string;
}

export interface GlobalSearchResult {
  id: string;
  referenceNumber: string;
  assetName: string;
  status: LohiLoadStatus;
}

@Injectable({
  providedIn: 'root',
})
export class LoadsService {
  private readonly loadId$ = this.routerState.listenForParamChange$(LOAD_ID).pipe(shareReplay(1));

  private loadDetails: RouterFinn<LoadDetails>;
  public get loadDetails$() {
    return this.loadDetails.get$();
  }

  private searchText$$ = new Subject<string>();
  private loading$$ = new BehaviorSubject<boolean>(false);
  private searchResults$$ = new BehaviorSubject<GlobalSearchResult[]>([]);

  public get searchResults$(): Observable<GlobalSearchResult[]> {
    return this.searchResults$$;
  }

  public get loading$(): Observable<boolean> {
    return this.loading$$;
  }

  constructor(private http: HttpClient, private routerState: RouterStateService) {
    this.loadDetails = new RouterFinn<LoadDetails>(null, this.loadId$, this.getLoadDetails);

    this.listenToSearchTextChange();
  }

  private getLoadDetails = async (loadId: string) => {
    return lastValueFrom(this.http.get<LoadDetails>(`${environment.api}/v2/vst/loads/${loadId}`));
  };

  public async toggleLoadAlerts(loadId: string, enabled = true) {
    try {
      await lastValueFrom(
        this.http.post<void>(`${environment.api}/v2/vst/loads/${loadId}/notifications`, {
          enabled,
        }),
      );
      this.loadDetails.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public search(text: string) {
    this.searchText$$.next(text);
  }

  private listenToSearchTextChange() {
    this.searchText$$
      .pipe(debounceTime(50))
      .pipe(
        switchMap((searchText) => {
          this.loading$$.next(true);
          if (searchText) {
            return this.http
              .get<{ results: GlobalSearchResult[] }>(`${environment.api}/v2/vst/global_search`, {
                params: { q: searchText },
              })
              .pipe(catchError(() => of({ results: new Array<GlobalSearchResult>() })));
          } else {
            return of({ results: new Array<GlobalSearchResult>() });
          }
        }),
      )
      .subscribe((results) => {
        this.loading$$.next(false);
        this.searchResults$$.next(results?.results || []);
      });
  }
}
