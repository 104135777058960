<div class="flex flex-col h-full bg-white dark:bg-gray-900">
  <div class="flex flex-col gap-y-2 flex-1">
    <a [routerLink]="['/']" class="p-2">
      <img src="assets/logos/vorto-logo.svg" class="hidden dark:block h-5 w-full" alt="logo" />
      <img src="assets/logos/vorto-logo-dark.svg" class="dark:hidden h-5 w-full" alt="logo" />
    </a>
    <a [routerLink]="['/']" class="font-semibold transition-colors duration-150 hover:text-blue-500 p-2 mx-2 rounded">
      My Facilities
    </a>
    <a
      *ngIf="isChepOrVorto$ | async"
      [routerLink]="['/asset_dashboard']"
      class="font-semibold transition-colors duration-150 hover:text-blue-500 p-2 mx-2 rounded"
    >
      Trailer Dashboard
    </a>
    <div
      (click)="openCommandPallet()"
      class="font-semibold transition-colors duration-150 hover:text-blue-500 p-2 mx-2 rounded cursor-pointer"
    >
      Search
    </div>
    <!-- <a
      [routerLink]="['/loads/awarded']"
      class="font-semibold hover:bg-gray-400 dark:hover:bg-zinc-600 p-2 mx-2 rounded"
    >
      Shipments
    </a>
    <div class="mt-auto"></div>
    <a [routerLink]="['/facilities']" class="font-semibold hover:bg-gray-400 dark:hover:bg-zinc-600 p-2 mx-2 rounded">
      Facilities
    </a>
    <a
      [routerLink]="['/users']"
      class="justify-self-end font-semibold hover:bg-gray-400 dark:hover:bg-zinc-600 p-2 mx-2 rounded"
    >
      Users
    </a>-->
    <div class="justify-self-end flex items-cemter flex-row justify-between w-full p-2 mt-auto">
      <div class="font-semibold">{{ (userInfo$ | async)?.userName }}</div>
      <button mat-flat-button color="warn" (click)="logout()">Logout</button>
    </div>
  </div>
</div>
