import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

// eslint-disable-next-line
declare const Intercom: any;

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private matSnackBar: MatSnackBar) {}
  public showMessage(message: string): void {
    this.matSnackBar.open(`${message}`, null, { duration: 2000 });
  }

  public showError(message: string): void {
    this.matSnackBar.open(message, null, {
      panelClass: ['bg-red-500'],
      duration: 2000,
    });
  }
}
