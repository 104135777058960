import { Pipe, PipeTransform } from '@angular/core';
import { stringOrDateToDate } from '../utilities/date';
import { differenceInHours, isBefore } from 'date-fns';

@Pipe({
  name: 'isBefore',
})
export class IsBeforePipe implements PipeTransform {
  public transform(value: string | Date, dateToCompare: string | Date = new Date()): boolean {
    if (!value) {
      return false;
    }
    const date = stringOrDateToDate(value);
    const parsedDateToCompare = stringOrDateToDate(dateToCompare);
    return isBefore(date, parsedDateToCompare);
  }
}
