import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'inTimezone',
})
export class InTimezonePipe implements PipeTransform {
  public transform(value: string | Date, timezone: string, format = 'M/d h:mma ZZZZ'): string {
    if (!value) {
      return null;
    }
    let inTz: DateTime;
    if (typeof value === 'string' || value instanceof String) {
      inTz = DateTime.fromISO(value as string, {
        zone: timezone,
      });
    } else {
      inTz = DateTime.fromJSDate(value, {
        zone: timezone,
      });
    }
    return inTz.toFormat(format, {
      locale: 'en-us',
    });
  }
}
