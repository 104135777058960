import { Pipe, PipeTransform } from '@angular/core';
import { TimezoneService } from '../services/timezone.service';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface LongLat {
  x: number;
  y: number;
}

@Pipe({
  name: 'timezoneName',
})
export class TimezoneNamePipe implements PipeTransform {
  constructor(private tzService: TimezoneService) {}

  public transform(value: LongLat): Observable<string> {
    if (!value) {
      return of(null);
    }
    return this.tzService.queryTimezoneForLocation$((value as LongLat).y, (value as LongLat).x).pipe(
      map((v) => v.name),
      catchError(() => of(null)),
    );
  }
}
