import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { catchError, Observable, switchMap, switchMapTo, throwError } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SnackBarService } from './snackbar.service';
import { NGXLogger } from 'ngx-logger';

export const NO_CONTENT_TYPE_HEADER = 'X-No-Content-Type';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService {
  constructor(
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private snackbar: SnackBarService,
    private logger: NGXLogger,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.isLoggedIn$.pipe(
      filter((isLoggedIn) => !!isLoggedIn),
      switchMapTo(this.afAuth.idToken),
      take(1),
      switchMap((token) => {
        const copy = req.clone({
          setHeaders: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: `Bearer ${token}`,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'web-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'web-timezone-offset-minutes': `${new Date().getTimezoneOffset()}`,
          },
        });

        if (!req.headers.get(NO_CONTENT_TYPE_HEADER)) {
          copy.headers.append('Content-Type', req.headers.get('Content-Type') || 'application/json');
        }

        return next.handle(copy).pipe(
          catchError((err) => {
            this.logError(err, true);
            return throwError(err);
          }),
        );
      }),
    );
  }

  private logError(error: any, showSnackbar: boolean = true) {
    this.logger.error(error);
    if (error instanceof HttpErrorResponse && showSnackbar) {
      if (error.error.isClientError) {
        this.snackbar.showError(error.error.messageToUser);
      } else {
        this.snackbar.showError('Unexpected Error Occurred Processing Request');
      }
    }
  }
}
