import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService, CustomerType } from '../services/auth.service';
import { filter, map } from 'rxjs/operators';
import { combineLatest, Observable, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[vstInternal]',
})
export class InternalDirective implements OnDestroy {
  private hasView = false;
  private destroy$$ = new Subject<void>();

  private isInternal$: Observable<boolean>;
  private elseRef?: TemplateRef<any>;

  // if not internal, show this template
  @Input() public set tbInternal(ref: TemplateRef<any>) {
    this.elseRef = ref;
  }

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef,
  ) {
    this.isInternal$ = this.authService.userInfo$.pipe(
      filter((v) => !!v),
      map((v) => v.internal),
    );
    this.listenForViewUpdates();
  }

  public ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  private listenForViewUpdates() {
    this.isInternal$.pipe(takeUntil(this.destroy$$)).subscribe({
      next: (internal) => {
        if (internal) {
          this.renderView();
        } else {
          this.renderElseView();
        }
      },
    });
  }

  private renderView() {
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
      this.cd.markForCheck();
    }
  }

  private renderElseView() {
    if (this.elseRef) {
      if (!this.hasView) {
        this.viewContainer.createEmbeddedView(this.elseRef);
        this.hasView = true;
        this.cd.markForCheck();
      }
    } else {
      this.clearView();
    }
  }

  private clearView() {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
      this.cd.markForCheck();
    }
  }
}
