import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'map',
})
export class MapPipe implements PipeTransform {
  public transform(value: any[], ...args: string[]): any[] {
    if (!value) {
      return [];
    }
    return value.map((value1) => value1[args[0]]);
  }
}
