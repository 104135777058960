import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LngLatBounds } from 'mapbox-gl';
import { MappableStop, XYPoint } from '../../types';

@Component({
  selector: 'vst-static-map',
  templateUrl: './static-map.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaticMapComponent {
  private _stops: MappableStop[];
  @Input() public set stops(stops: MappableStop[] | XYPoint) {
    if (Array.isArray(stops)) {
      this._stops = stops;
    } else if (stops) {
      this._stops = [
        {
          lngLat: {
            x: stops.x,
            y: stops.y,
          },
        },
      ];
    }
    if (this._stops?.length) {
      this.url = this.setupStaticMapboxMap();
    }
  }
  public url: string;
  @Input() public mapStyle = 'streets-v12';
  @Input() public padding = 100;
  @Input() public width = 400;
  @Input() public height = 300;

  constructor() {}

  private setupStaticMapboxMap() {
    const bounds = new LngLatBounds();
    this._stops.forEach((stop) => {
      bounds.extend([stop.lngLat.x, stop.lngLat.y]);
    });

    const overlay = this._stops
      .map((stop) => {
        return `pin-l${stop.sequence ? '-' + stop.sequence : ''}+2d70a8(${stop.lngLat.x},${stop.lngLat.y})})`;
      })
      .join(',');

    return `https://api.mapbox.com/styles/v1/mapbox/${this.mapStyle}/static/${overlay}/auto/${this.width}x${this.height}?padding=${this.padding}&access_token=pk.eyJ1Ijoic2F0ZXN0ZGV2IiwiYSI6ImNrYmVraDllZDBtcXQycHFlbzVqYXk0cnMifQ.HzVySDvy0mLDW03ykD2HNw`;
  }
}
