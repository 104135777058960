import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { Observable, Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

interface Sortable {
  sortByHeader$: Observable<string>;
  sortAscending$: Observable<boolean>;
}

@Component({
  selector: 'vst-sort-indicator',
  templateUrl: './sort-indicator.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortIndicatorComponent implements OnDestroy {
  private destroy$$ = new Subject<void>();
  private headerName$$ = new BehaviorSubject<string>(null);
  private activeColumn$$ = new BehaviorSubject<boolean>(false);
  public activeColumn$: Observable<boolean> = this.activeColumn$$.asObservable();
  private reversed$$ = new BehaviorSubject<boolean>(false);
  public reversed$: Observable<boolean> = this.reversed$$.asObservable();

  @Input() public set activeColumn(bool: boolean) {
    this.activeColumn$$.next(bool);
  }
  @Input() public set reversed(bool: boolean) {
    this.reversed$$.next(bool);
  }
  @Input() public set sortingObservable(sortable: Sortable) {
    this.setupSortable(sortable);
  }
  @Input() public set headerName(name: string) {
    this.headerName$$.next(name);
  }

  constructor() {}

  public ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  private setupSortable(sortable: Sortable) {
    this.destroy$$.next();

    sortable.sortAscending$.pipe(takeUntil(this.destroy$$)).subscribe((bool) => {
      this.reversed = !bool;
    });

    combineLatest([sortable.sortByHeader$, this.headerName$$])
      .pipe(takeUntil(this.destroy$$))
      .subscribe(([sortByHeader, headerName]) => {
        if (!headerName) {
          this.activeColumn$$.next(false);
        }
        this.activeColumn$$.next(sortByHeader === headerName);
      });
  }
}
