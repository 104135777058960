<mat-sidenav-container>
  <mat-sidenav
    #sidenav
    mode="over"
    class="w-full lg:w-1/2 xl:w-1/3 bg-white dark:bg-zinc-900 max-h-screen"
    [autoFocus]="false"
  >
    <vst-side-nav (click)="sidenav.close()"></vst-side-nav>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="flex flex-col h-screen">
      <div
        class="h-6 flex bg-white dark:bg-gray-900 drop-shadow z-10"
        style="min-height: 48px"
        *ngIf="isLoggedIn$ | async"
      >
        <div class="w-8 flex items-center justify-center">
          <mat-icon class="cursor-pointer" (click)="sidenav.open()">menu</mat-icon>
        </div>
        <div class="flex-1 flex items-center">
          <a [routerLink]="['/home']" class="mx-auto pr-4">
            <img src="assets/logos/vorto-dark.svg" class="hidden dark:block h-2" alt="logo" />
            <img src="assets/logos/vorto.svg" class="dark:hidden h-2" alt="logo" />
          </a>
        </div>
      </div>
      <div class="flex-1">
        <router-outlet class="hidden"></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
