import { Directive, HostListener, Input } from '@angular/core';
import { NgxMatDatetimeInput } from '@angular-material-components/datetime-picker';

@Directive({
  selector: '[vstDatePicker]',
})
export class DatePickerDirective {
  constructor(private datePicker: NgxMatDatetimeInput<Date>) {}

  @HostListener('click', ['$event'])
  public onClick(event: Event) {
    event.stopPropagation();
    this.datePicker._datepicker.open();
  }
}
