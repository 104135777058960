import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { lastValueFrom, Observable } from 'rxjs';
import * as LogRocket from 'logrocket';
import { environment } from '../environments/environment';
import { filter, take } from 'rxjs/operators';
import { IntercomService } from './shared/services/intercom.service';

@Component({
  selector: 'vst-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent implements OnInit {
  public title = '5F Customer Portal';
  public isLoggedIn$: Observable<boolean>;

  constructor(private authService: AuthService, private intercomService: IntercomService) {
    this.isLoggedIn$ = this.authService.isLoggedIn$;
  }

  public ngOnInit(): void {
    this.enableLogRocket();
  }

  private enableLogRocket() {
    if (window.location.hostname === 'localhost') {
      return;
    }
    const logRocketOptions = {
      release: '0.0.0',
    };

    if (environment.production) {
      LogRocket.init('stkslu/vst-prod', logRocketOptions);
    } else {
      return;
    }

    this.lgIdentify();
  }

  private async lgIdentify() {
    const user = await lastValueFrom(
      this.authService.userInfo$.pipe(
        filter((t) => !!t),
        take(1),
      ),
    );
    LogRocket.identify(user.userId, {
      name: user.userName,
      email: user.userEmail,
    });
  }
}
