import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './side-nav/side-nav.component';
import { MapPipe } from './pipes/map.pipe';
import { TrackByKeyPipe } from './pipes/track-by-key.pipe';
import { StandardDatePipe } from './pipes/standard-date.pipe';
import { StandardTimePipe } from './pipes/standard-time.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { NumberBadgeComponent } from './components/number-badge/number-badge.component';
import { CurrencyInPenniesPipe } from './pipes/currency-in-pennies.pipe';
import { DashIfNothingPipe } from './pipes/dash-if-nothing.pipe';
import { IsPhonePipe } from './pipes/is-phone.pipe';
import { PageTitleDirective } from './directives/page-title.directive';
import { HighlightTextDirective } from './directives/highlight-text.directive';
import { TooltipModule } from 'ng2-tooltip-directive-ng13fix';
import { RouterModule } from '@angular/router';
import { IsImagePipe } from './pipes/is-image.pipe';
import { CompareWithPipe } from './pipes/compare-with.pipe';
import { CurrencyInputDirective } from './directives/money.directive';
import { NumberInputDirective } from './directives/number-input.directive';
import { RecordCallDirective } from './directives/record-call.directive';
import noData from 'highcharts/modules/no-data-to-display';
import { MinutesToHumanPipe } from './pipes/minutes-to-human.pipe';
import { AgoPipe } from './pipes/ago.pipe';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { AddMinutesPipe } from './pipes/add-minutes.pipe';
import { AddressSearchComponent } from './components/address-search/address-search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { StopMapComponent } from './components/stop-map/stop-map.component';
import { InternalDirective } from './directives/internal.directive';
import { SumPipe } from './pipes/sum.pipe';
import { InHoursPipe } from './pipes/in-hours.pipe';
import { IsBeforePipe } from './pipes/is-before.pipe';
import { DatePickerDirective } from './directives/date-picker.directive';
import { JoinWithAndPipe } from './pipes/join-with-and.pipe';
import { TrailerTypePipe } from './pipes/trailer-type.pipe';
import { SortIndicatorComponent } from './components/sort-indicator/sort-indicator.component';
import { IsAfterPipe } from './pipes/is-after.pipe';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { InTimezonePipe } from './pipes/in-timezone.pipe';
import { TimezoneNamePipe } from './pipes/timezone-name.pipe';
import { StopTypePipe } from './pipes/stop-type.pipe';
import { TextToDateComponent } from './components/text-to-date/text-to-date.component';
import { AssetPipe } from './pipes/asset-type.pipe';
import { DifferenceInMinutesPipe } from './pipes/difference-in-minutes.pipe';
import { StaticMapComponent } from './components/static-map/static-map.component';
import { MaterialModule } from './material/material.module';
import * as Highcharts from 'highcharts';
import { PlusMorePipe } from './pipes/plus-more.pipe';
import { IsTodayPipe } from './pipes/is-today.pipe';
import { TrailerStatusPipe } from './pipes/trailer-status.pipe';
import { LohiLoadStatusPipe } from './pipes/lohi-load-status.pipe';

noData(Highcharts);

const tooltipOptions = {
  placement: 'top',
  'show-delay': 200,
  'tooltip-class': 'tooltip-overrides',
};

@NgModule({
  declarations: [
    FeatureFlagDirective,
    TrackByKeyPipe,
    SideNavComponent,
    MapPipe,
    StandardDatePipe,
    StandardTimePipe,
    PhonePipe,
    NumberBadgeComponent,
    CurrencyInPenniesPipe,
    DashIfNothingPipe,
    IsPhonePipe,
    PageTitleDirective,
    HighlightTextDirective,
    IsImagePipe,
    CompareWithPipe,
    CurrencyInputDirective,
    NumberInputDirective,
    RecordCallDirective,
    MinutesToHumanPipe,
    AgoPipe,
    AddMinutesPipe,
    AddressSearchComponent,
    StopMapComponent,
    InternalDirective,
    SumPipe,
    InHoursPipe,
    IsBeforePipe,
    IsAfterPipe,
    DatePickerDirective,
    JoinWithAndPipe,
    TrailerTypePipe,
    SortIndicatorComponent,
    InTimezonePipe,
    TimezoneNamePipe,
    StopTypePipe,
    TextToDateComponent,
    AssetPipe,
    DifferenceInMinutesPipe,
    StaticMapComponent,
    PlusMorePipe,
    IsTodayPipe,
    TrailerStatusPipe,
    LohiLoadStatusPipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TooltipModule.forRoot(tooltipOptions),
    RouterModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    ReactiveFormsModule,
  ],
  exports: [
    TrackByKeyPipe,
    SideNavComponent,
    MapPipe,
    StandardDatePipe,
    StandardTimePipe,
    PhonePipe,
    NumberBadgeComponent,
    CurrencyInPenniesPipe,
    DashIfNothingPipe,
    IsPhonePipe,
    PageTitleDirective,
    HighlightTextDirective,
    IsImagePipe,
    CompareWithPipe,
    CurrencyInputDirective,
    NumberInputDirective,
    RecordCallDirective,
    MinutesToHumanPipe,
    AgoPipe,
    AddMinutesPipe,
    AddressSearchComponent,
    StopMapComponent,
    InternalDirective,
    SumPipe,
    InHoursPipe,
    IsBeforePipe,
    IsAfterPipe,
    DatePickerDirective,
    JoinWithAndPipe,
    TrailerTypePipe,
    SortIndicatorComponent,
    FeatureFlagDirective,
    InTimezonePipe,
    TimezoneNamePipe,
    StopTypePipe,
    TextToDateComponent,
    AssetPipe,
    DifferenceInMinutesPipe,
    StaticMapComponent,
    TooltipModule,
    MaterialModule,
    PlusMorePipe,
    IsTodayPipe,
    TrailerStatusPipe,
    LohiLoadStatusPipe,
  ],
  providers: [TrailerTypePipe, StopTypePipe, InTimezonePipe, TrailerStatusPipe, StandardDatePipe],
})
export class SharedModule {}
