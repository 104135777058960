import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, interval, lastValueFrom, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, take, throttleTime } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

/*
WARNING - THIS USES THE tour_customer.feature_flag table, not the regular one
 */

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private overrides$$ = new BehaviorSubject<Record<string, boolean>>({});
  private flags$$ = new BehaviorSubject<Record<string, boolean>>(null);
  private flagPoll$$ = new Subject<null>();
  private apiUrl$$ = new BehaviorSubject<string>(environment.api);

  public flags$ = combineLatest([this.flags$$, this.overrides$$]).pipe(
    filter(([flags]) => !!flags),
    map(([flags, overrides]) => Object.assign({}, flags, overrides)),
    shareReplay(1),
  );

  constructor(private httpClient: HttpClient) {
    this.setupWindowFunctions();
    this.loadFlags();
    this.pollFlags();
  }

  public isFlagActive$(flag: string): Observable<boolean> {
    return this.flags$.pipe(
      map((flags) => {
        return processFlag(flags, flag);
      }),
    );
  }

  private pollFlags() {
    this.flagPoll$$.pipe(throttleTime(5 * 60 * 1000)).subscribe(() => {
      this.loadFlags();
    });
    interval(5 * 60 * 1000).subscribe(() => {
      this.flagPoll$$.next(null);
    });
  }

  private async loadFlags() {
    const flags = await lastValueFrom(
      this.httpClient.get<{ name: string; active: boolean }[]>(
        `${this.apiUrl$$.value}/v1/external/broker_portal/feature_flags`,
      ),
    );
    if (flags) {
      const flagMap: Record<string, boolean> = (flags || []).reduce((acc, flag) => {
        acc[flag.name] = flag.active;
        return acc;
      }, {});
      this.flags$$.next(flagMap);
    } else {
      this.flags$$.next({});
    }
  }

  private setupWindowFunctions() {
    (window as any).listFeatureFlags = () => ({
      production: environment.production,
      flags: this.flags$$.value,
    });

    if (!environment.production) {
      (window as any).setFeatureFlagOverrides = (overrides: Record<string, boolean>) => {
        this.overrides$$.next(overrides || {});
        // Send out same flags so observable pushes out again
        if (Object.keys(overrides).length === 0 && overrides.constructor === Object) {
          console.log('Feature flags are now in sync with firebase');
        } else {
          console.log(
            'Feature flags will be out of sync with firebase until you refresh or call setFeatureFlagOverrides({})',
          );
        }
      };
    }
  }
}

export function processFlag(flags: Record<string, boolean>, flag: string): boolean {
  let targetState = true;
  if (flag.startsWith('!')) {
    flag = flag.substr(1);
    targetState = false;
  }
  if (Reflect.has(flags, flag)) {
    return flags[flag] === targetState;
  }
  return false;
}
