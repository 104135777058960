export const stringOrDateToDate = (date: string | Date) => {
  if (typeof date === 'string') {
    return new Date(date);
  }
  return date;
};

export const getUserShortTimezone = (): string | undefined => {
  return /.*\s(.+)/.exec(new Date().toLocaleDateString(navigator.language, { timeZoneName: 'short' }))?.[1];
};
