import { FormControl, FormGroup } from '@angular/forms';
import { LoadingType, StopType } from '../modules/loads/loads.service';
import { YardManagerTrailerStatus } from './services/yard-manager.service';

export interface LongLat {
  longitude: number;
  latitude: number;
}

export interface XYPoint {
  x: number;
  y: number;
}

export interface NullableXYPoint extends XYPoint {
  valid: boolean;
}

export type SalesTrailerType =
  | 'reefer'
  | 'dry_van'
  | 'power_only'
  | 'flatbed'
  | 'hopper'
  | 'pneumatic'
  | 'belly_dump'
  | 'end_dump'
  | 'straight_box'
  | 'intermodal_chassis'
  | 'tanker'
  | 'stepdeck'
  | 'low_boy'
  | 'conestoga'
  | '40_foot_high_cube_container'
  | '20_foot'
  | '40_foot';

export interface MappableStop {
  sequence?: number | string;
  lngLat: XYPoint;
  color?: string;
}

export type ControlsOf<T extends Record<string, any>> = {
  [K in keyof T]: T[K] extends Record<any, any> ? FormGroup<ControlsOf<T[K]>> : FormControl<T[K]>;
};

export interface AppointmentsToRespondTo {
  facilities: AppointmentFacility[];
}

export interface AppointmentFacility {
  facilityId: string;
  facilityName: string;
  timezone: string;
  datesToConfirm: AppointmentDateToConfirm[];
}

export interface AppointmentDateToConfirm {
  date: string;
  appointments: AppointmentDetail[];
}

export interface AppointmentDetail {
  id: string;
  loadId: string;
  referenceNumber: string | null;
  suggestedTimes: AppointmentDetailSuggestTime[];
  hasResponded: boolean;
  requestedAt: string;
  respondedAt: string | null;
  respondedByName: string | null;
  appointmentSetTime: string | null;
  hasUnresovledProposedTime: boolean;
  dockNumber: string | null;
  trailerType: string;
  loadType: 'live_load';
  quantity: number;
  commodity: string;
  unitAbbreviation: string;
  stopType: string;
  ediPayload: string;
  trailerId: number;
  trailerName: string;
}

export interface AppointmentDetailSuggestTime {
  suggestionId: number;
  appointmentTime: string;
  isBestTime: boolean;
}

export interface AppointmentResponse {
  acceptedSuggestionId?: number;
  proposedAlternativeTime?: string;
  dockNumber?: string;
}

export interface Shipment extends AppointmentDetail {
  isTrackingLate: number;
  timeWhenDiscountEnds: string;
  carrierScac: string;
  hasArrived: boolean;
  eta: string | null;
}

export interface MyShipments {
  facilities: ShipmentFacility[];
}

export interface ShipmentFacility {
  facilityId: string;
  facilityName: string;
  customerReferenceNumber: string | null;
  timezone: string;
  datesToConfirm: ShipmentDatesConfirmed[];
}

export interface ShipmentDatesConfirmed {
  date: string;
  loadsLeft: number;
  loadsCompleted: number | null;
  averageDwellTimeMinutes: number | null;
  averageDwellTimeMinutesOnTime: number | null;
  averageDwellTimeMinutesEarlyLate: number | null;
  appointments: Shipment[];
}

export interface FacilityListItem extends ShipmentFacility {
  inventoryTargetType: string;
  inventoryMaxCount: number;
  inventoryTargetCount: number;
  trailersMatching: number;
  lastUpdatedAt: string | null;
  lastUpdatedByName: string | null;
  alarm: boolean;
  etaEmailEnabled: boolean;
  yardEmailEnabled: boolean;
  belongsToFacility: boolean;
  canPredictInventory: boolean;
}

export interface FacilityCompletedLoad {
  id: string;
  referenceNumber: string | null;
  completedAt: string | null;
  type: StopType;
  arrivedAt: string | null;
  completedAtStop: string | null;
  trailerId: number;
  trailerName: string;
}

export interface FacilitySchedulingUser {
  id: string;
  name: string;
  email: string;
  shouldSendBookingEmails: boolean;
}

export interface FacilityUsers {
  users: FacilitySchedulingUser[];
  excludedFromETAEmail: string[];
  excludedFromYardEmail: string[];
}

export interface CreateLoadRecommendation {
  recommendationId: number;
  trailerType: SalesTrailerType;
  stops: CreateLoadRecommendationStop[];
}

export interface CreateLoadRecommendationFlat {
  recommendationId: number;
  trailerType: SalesTrailerType;
  pickupStop: CreateLoadRecommendationStop;
  dropoffStop: CreateLoadRecommendationStop;
}

export interface CreateLoadRecommendationStop {
  sequence: number;
  facilityId: string;
  facilityName: string;
  stopType: StopType;
  loadingType: LoadingType;
  extremaWindowStartTime: string;
  extremaWindowEndTime: string;
  facilityTimezone: string;
}

export interface CancelLoadRecommendation {
  loadId: string;
  loadReference: string | null;
  stops: CancelLoadRecommendationStop[];
}

export interface CancelLoadRecommendationFlat {
  loadId: string;
  loadReference: string | null;
  pickupStop: CancelLoadRecommendationStop;
  dropoffStop: CancelLoadRecommendationStop;
}

export interface CancelLoadRecommendationStop {
  loadId: string;
  facilityId: string;
  facilityName: string;
}

export interface InventoryPrediction {
  trailerCounts: InventoryDataPoint[];
  itemCounts: InventoryDataPoint[];
  emptyCounts: InventoryDataPoint[];
  daysOfInventory: InventoryDataPoint[];
  shipments: InventoryShipment[];
  canPredictionInventory: boolean;
  daysOfInventoryTarget: number;
  daysOfInventoryCritical: number;
  trailerInventoryCritical: number;
}

export interface InventoryHistory {
  trailerCounts: InventoryDataPoint[];
  daysOfInventory: InventoryDataPoint[];
  emptyCounts: InventoryDataPoint[];
}

export interface InventoryDataPoint {
  count: number;
  time: string;
}

export interface InventoryShipment {
  loadId: string | null;
  arrivalTime: string;
  isEnroute: boolean;
}

export interface InboundEmpty {
  assetId: number;
  name: string;
}

export interface EarlyArrivalSettings {
  earlyArrivalEnabled: boolean;
  earlyArrivalExpiresAt: string | null;
  earlyArrivalDefaultValue: boolean;
  setByUserId: string | null;
  setByUserName: string | null;
}

export enum TrailerLoadedStatus {
  loaded = 'loaded',
  empty = 'empty',
  unknown = 'unknown',
}

export enum LAMStatus {
  readyForUse = 'ready_for_use',
  maintenanceNeeded = 'maintenance_needed',
  inspectionNeeded = 'inspection_needed',
  notAvailable = 'not_available',
  inCustody = 'in_custody',
}

export interface TrailerDashboardItem {
  assetId: string;
  assetName: string;
  facilityName: string;
  driverName: string;
  assetStatus: LAMStatus;
  assetStatusUpdatedAt: string;
  assetStatusUpdatedBy: string;
  loadedStatus: TrailerLoadedStatus;
  loadedStatusUpdatedAt: string;
  loadedStatusUpdatedBy: string;
  lngLat: NullableXYPoint;
  stateCode: string;
  inboundOutbound: 'inbound' | 'outbound' | null;
  status: YardManagerTrailerStatus;
}

export enum LohiLoadStatus {
  pending = 'pending',
  inProgress = 'in_progress',
  completed = 'completed',
  cancelled = 'cancelled',
  notAssigned = 'not_assigned',
  atYard = 'at_yard',
  assetAvailable = 'asset_available',
  claimed = 'claimed',
  unclaimed = 'unclaimed',
}

export interface FacilityNote {
  id: number;
  creatorName: string;
  note: string;
  createdAt: string;
}

export interface PoolInfo {
  name: string;
  stats: PoolInfoStats[];
}

export interface PoolInfoStats {
  period: Period;
  periodDescription: string;
  numberOfDrivers: number;
  numberOfDriversCustomer: number;
  numberOfShipmentsCustomer: number;
  numberOfShipments: number;
  averageTurnRate: number;
  maximumTurnRate: number;
}

export enum Period {
  Yesterday = -1,
  Today = 0,
  Tomorrow = 1,
}
