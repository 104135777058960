import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { MappableComponent, SATELLITE } from '../mappable/mappable.component';
import { lastValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import * as mapboxgl from 'mapbox-gl';
import { Marker } from 'mapbox-gl';
import { MappableStop, XYPoint } from '../../types';

@Component({
  selector: 'vst-stop-map',
  templateUrl: './stop-map.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StopMapComponent extends MappableComponent implements OnChanges, AfterViewInit {
  private driverMarker: mapboxgl.Marker;
  @Input() public stops: MappableStop[] = [];
  @Input() public driverLnglat: XYPoint;
  @Input() public height = 209;
  @Input() public offset = false;

  private mapInit = false;

  constructor() {
    super(SATELLITE);
  }

  private async addMarkers() {
    if (!this.stops || !this.mapInit) {
      return;
    }
    this.clearMarkers();
    let offset = 0.001;
    for (const stop of this.stops) {
      const el = document.createElement('div');
      el.className =
        'rounded-full text-white text-2xl text-center flex flex-row items-center justify-center border border-white';
      el.style.width = '30px';
      el.style.height = '30px';
      el.innerText = `${stop.sequence}`;
      el.style.backgroundColor = stop.color || '#448AFF';
      const marker = new mapboxgl.Marker(el);
      marker.setLngLat([stop.lngLat.x, stop.lngLat.y]);
      if (this.offset && this.markers.some((value) => value.getLngLat().distanceTo(marker.getLngLat()) === 0)) {
        marker.setOffset([offset, -offset]);
        offset *= -1.001;
      }
      this.addMarker(marker);
    }
    if (this.driverLnglat) {
      this.addDriverMarker(this.driverLnglat);
    }
    this.fitMarkers(70);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.mapInit) {
      return;
    }
    this.addMarkers();
  }

  public override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.mapInit = true;
    this.addMarkers();
  }

  private addDriverMarker(lnglat: XYPoint) {
    if (!lnglat || lnglat?.x === 0 || lnglat?.y === 0) {
      return;
    }
    const el = document.createElement('div');
    el.className =
      'rounded-full text-white text-2xl text-center flex flex-row items-center justify-center border border-white';
    el.style.width = '30px';
    el.style.height = '30px';
    el.innerHTML = `<span class="material-icons">local_shipping</span>`;
    el.style.backgroundColor = '#448AFF';
    this.driverMarker = new Marker(el);
    this.driverMarker.setLngLat([(lnglat as XYPoint)?.x, (lnglat as XYPoint)?.y]);
    this.addMarker(this.driverMarker);
  }
}
