import { Pipe, PipeTransform } from '@angular/core';

const values: Record<string, string> = {
  pickup: 'Pickup',
  dropoff: 'Dropoff',
  empty_asset_pickup: 'Empty Asset Pickup',
  empty_asset_dropoff: 'Empty Asset Dropoff',
  loaded_asset_storage_pickup: 'Loaded Asset Storage Pickup',
  loaded_asset_storage_dropoff: 'Loaded Asset Storage Dropoff',
};

@Pipe({
  name: 'stopType',
})
export class StopTypePipe implements PipeTransform {
  public transform(value: string): string {
    return values[value] || value;
  }
}
