import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  HostListener,
} from '@angular/core';
import { AuthService, VSTUserInfo } from '../services/auth.service';
import { map, Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadSearchComponent } from '../../modules/loads/load-search/load-search.component';

@Component({
  selector: 'vst-side-nav',
  templateUrl: './side-nav.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavComponent {
  public userInfo$: Observable<VSTUserInfo>;
  public isChepOrVorto$: Observable<boolean>;
  private ref: MatDialogRef<LoadSearchComponent, any>;

  constructor(private authService: AuthService, private matDialog: MatDialog) {
    this.userInfo$ = this.authService.userInfo$;
    this.isChepOrVorto$ = this.userInfo$.pipe(
      map((v) => {
        return v?.userEmail.toLowerCase().endsWith('@chep.com') || v?.userEmail.toLowerCase().endsWith('@vorto.ai');
      }),
    );
  }

  public logout() {
    this.authService.logout();
  }

  private async handleCommandPallet(event: KeyboardEvent) {
    event.preventDefault();
    this.openCommandPallet();
  }

  public openCommandPallet() {
    if (this.ref?.componentInstance instanceof LoadSearchComponent) {
      return;
    }
    this.ref?.close();
    this.ref = this.matDialog.open(LoadSearchComponent, {
      autoFocus: true,
      panelClass: ['w-full', 'md:w-1/2', 'lg:w-1/3', 'no-padding-dialog'],
    });
  }

  @HostListener('document:keydown.control.k', ['$event'])
  public commandPallet(event: KeyboardEvent) {
    this.handleCommandPallet(event);
  }

  @HostListener('document:keydown.meta.k', ['$event'])
  public macCommandPallet(event: KeyboardEvent) {
    this.handleCommandPallet(event);
  }

  @HostListener('document:keydown.control.shift.enter', ['$event'])
  public async onShiftEnter(event: KeyboardEvent) {
    this.handleCommandPallet(event);
  }

  @HostListener('document:keydown.control.enter', ['$event'])
  public async onControlEnter(event: KeyboardEvent) {
    this.handleCommandPallet(event);
  }
}
